@import url('https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap');

body{
  background-color: #eeeeee;
  .main{
    .second{
      overflow: hidden;
      position: fixed;
      bottom: 0;
      width: 105%;
      .col-3{
        cursor: pointer;
        background-color: #284fa1;
        padding: 20px;
        text-align: center;
        img{
          width:32px;
          height: 32px;
        }
        h6{
          color: #fff;
        }
      }
    }
    .container{
      .row{
        .col-4{
          padding-left: 0;
          padding-right: 0;
          overflow: scroll;
          height: 652px;
          background-color: #ffffff;
          .top-image{
            background-color: #284fa1;
            padding: 20px;
            img{
             height: 60px;
              width: 75px;
            }
          }

          .list-group{
            width: 100%;
            .list-group-item{
              border: none;
              padding: 18px;
              a{
                color: #000;
                text-decoration: none;
              }
              &:nth-child(even){
                background-color: #f7f7f7;
              }
            }
          }

        }

        .col-8{
          overflow: scroll;
          height: 652px;
          background-color: #ffffff;
          padding: 20px;
          .video{
           width:100%!important;
            div{
              width: 100% !important;
            }
          }
          h3{
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 15px;
          }
          p{
            font-family: 'Hina Mincho', serif;
            font-size: 20px;
            font-weight: 500;
          }
          h6{
            color: #093483;
            font-size: 2rem;
          }
          .react-audio-player{
            width: 100%;
            margin-bottom: 20px;
            margin-top: 20px;
          }
          img{
            width: 100%;
          }
          .must{
            display: flex;
            //align-items: center;
            justify-content: space-between;
            background-color: #f7f7f7;
            padding: 20px;
            margin-bottom: 20px;
            div{
              flex: 0 0 50%;
              max-width: 50%;
              h4{
                margin-top: 15px;
              }
            }
          }
          .MuiPaper-root{
            cursor: pointer;
            padding: 15px;
            box-shadow: 0 0 10px rgba(0,0,0,0.3);
            border-radius: 12px;
            background-color: #fff;
            margin-top: 24px;
            margin-bottom: 24px;
          }
        }
      }

    }
  }
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
  }
  @media (max-width: 600px) {
    .main{
      .container{
        .col-4, .col-8{
          flex: 0 0 100%;
          max-width: 100%;
        }
        .col-4{
          overflow: hidden!important;
          height: 100% !important;
          .list-group{
            overflow: auto;
            white-space: nowrap;
            .list-group-item{
              display: inline-block;
              a{

              }
            }
          }
        }
        .col-8{
          .must{
            display: block!important;
            div{
              flex: 0 0 100% !important;
              max-width: 100%!important;
              text-align: center;
            }
          }
        }
      }
      .second{
        .col-3{
          img{
            width: 25px!important;
            height: 25px!important;
          }
          h6{
            font-size: 0.8rem!important;
          }
        }
      }
    }
  }
}
